<template>
	<div class="form-input__group">
		<div class="input-container">
			<input
				:id="fieldName"
				:type="showPassword ? 'text' : fieldType"
				:placeholder="placeholder"
				:class="{
					'has-error': !isValid,
				}"
				:value="value"
				:disabled="disabled"
				@input="(e) => $emit('input', e.target.value)"
				@keyup="(e) => $emit('keyup', e.target.value)"
			>
			<div
				v-if="fieldType === 'password'"
				class="password-show"
				@click="togglePasswordInputType"
			>
				<font-awesome-icon
					v-if="!showPassword"
					class="icon"
					icon="fa-regular fa-eye"
				/>
				<font-awesome-icon
					v-else
					class="icon"
					icon="fa-regular fa-eye-slash"
				/>
			</div>
		</div>
		<div
			v-if="helpText && isValid"
			class="help-text"
		>
			{{ helpText }}
		</div>
		<div
			v-if="!isValid"
			class="error-message"
		>
			{{ errors[fieldName][0] }}
		</div>
		<div
			v-if="successMessage"
			class="success-message"
		>
			{{ successMessage }}
		</div>
	</div>
</template>

<script>
export default {

  props: {
    errors: {
      type: Object,
      required: false,
      default: null,
    },
    fieldName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    fieldType: {
      type: String,
      required: false,
      default: 'text',
    },
    helpText: {
      type: String,
      required: false,
      default: null,
    },
    successMessage: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: ' ',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showPassword: false,
    };
  },

  computed: {
    isValid() {
      return !(this.errors && this.errors[this.fieldName] && this.errors[this.fieldName].length > 0);
    },
  },

  methods: {
    togglePasswordInputType() {
      this.showPassword = !this.showPassword;
    },
  },

};
</script>

<style lang="scss" scoped>
@import "_sass/_global.scss";
@import "_sass/vendors/_include-media.scss";

.password-show {
    position: absolute;
    right: 15px;
    cursor: pointer;
    .icon {
        color: $gray-550;
    }
}

.form-input__group {
  margin-bottom: 1rem;
}
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  label {
    position: absolute;
    margin: 0;
    color: $gray-550;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    span {
      color: $red;
      font-size: 20px;
      display: inline-block;
      padding-bottom: 5px;
    }
  }
  input {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
    height: 40px;
    border: 1px solid $light;
    border-radius: 6rem;
    width: 100%;
    background-color: $light;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $gray-900;
    &:active ~ label,
    &:not(:placeholder-shown) ~ label,
    &:focus ~ label {
      display: none;
    }
    &.has-error {
        border: 1px solid $red;
        color: $red;
    }
  }
}

.help-text {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 15px;
  margin-top: 5px;
  line-height: 10px;
}
</style>
